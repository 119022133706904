@use 'lib/styles/defs/breakpoints';

@mixin reset-horizontal-paddings($side) {
	padding-#{$side}: 4px !important;
	@media screen and (min-width: breakpoints.$breakpoint-md) {
		padding-#{$side}: 8px !important;
	}
	@media screen and (min-width: breakpoints.$breakpoint-lg) {
		padding-#{$side}: 12px !important;
	}
}
// TODO: replace usages with .bg-gradient-3
@mixin gradientViolet {
	background: linear-gradient(
		to right bottom,
		#5d1082,
		#4d137b,
		#3d1473,
		#2d146a,
		#1d1361,
		#191a66,
		#14206b,
		#0d266f,
		#163683,
		#1f4797,
		#2758ab,
		#2e6ac0
	);
}
// TODO: replace usages with .bg-gradient-2
@mixin gradientRed {
	background: radial-gradient(110% 220% at 0% 140%, #13a3f4 0%, rgba(22, 158, 255, 0.5) 40%, rgba(16, 136, 222, 0) 100%),
		radial-gradient(290% 800% at 220% -440%, #dd1122 30%, #001155 100%);
}
// TODO: replace usages with .bg-gradient-1
@mixin gradientBlue {
	background: radial-gradient(110% 240% at 0% 130%, rgba(19, 163, 244, 0.9) 0%, rgba(22, 158, 255, 0.5) 55%, rgba(16, 136, 222, 0) 130%), #001155;
}
// TODO: replace usages with .bg-cloud
@mixin gradientCloud {
	background: #f9f9f9;
}

@mixin gradientPink {
	background: linear-gradient(214.35deg, #d8e1f9 -0.2%, #5990f9 55.31%, #942fbe 98.75%);
}
